import { createStore } from 'vuex';
import { jwtDecode } from 'jwt-decode';
import api from '../axios';

export default createStore({
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.getItem('token') || '',
    selectedHospital: localStorage.getItem('selectedHospital') ? JSON.parse(localStorage.getItem('selectedHospital')) : null,
    isLoading: false,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },

    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },

    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },

    logout(state) {
      state.user = null;
      state.token = '';
      state.selectedHospital = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('selectedHospital');
    },

    setSelectedHospital(state, hospital) {
      state.selectedHospital = hospital;
      localStorage.setItem('selectedHospital', JSON.stringify(hospital));
    },
  },

  actions: {
    async updatePhoneNotificationSeen({ commit, state }) {
      try {
        await api.put('/account', { hasSeenPhoneNotification: true }, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        const updatedUser = { ...state.user, hasSeenPhoneNotification: true };
        commit('setUser', updatedUser);
      } catch (error) {
        console.error('Error updating phone notification status:', error);
      }
    },
    setToken({ commit }, token) {
      commit('setToken', token);
    },

    setUser({ commit }, user) {
      commit('setUser', user);
    },

    async login({ commit }, credentials) {
      try {
        commit('setLoading', true);
        const response = await api.post('/auth/login', credentials);
        const data = response.data;

        commit('setToken', data.token);
        const user = jwtDecode(data.token);
        commit('setUser', user);
      } catch (error) {
        throw new Error(error.response.data.message);
      } finally {
        commit('setLoading', false);
      }
    },

    async register({ commit }, userData) {
      try {
        commit('setLoading', true);
        const response = await api.post('/auth/register', userData);
        const data = response.data;

        commit('setToken', data.token);
        const user = jwtDecode(data.token);
        commit('setUser', user);
      } catch (error) {
        throw new Error(error.response.data.message);
      } finally {
        commit('setLoading', false);
      }
    },

    async updateAccount({ commit, state }, accountData) {
      try {
        commit('setLoading', true);
        const response = await api.put('/account', accountData, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });

        commit('setUser', response.data.user);

        if (accountData.currentPassword && accountData.newPassword) {
          await api.put('/account/change-password', {
            currentPassword: accountData.currentPassword,
            newPassword: accountData.newPassword,
          }, {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          });
        }
      } catch (error) {
        console.error('Error updating account information:', error);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },

    logout({ commit }) {
      commit('logout');
    },

    selectHospital({ commit }, hospital) {
      commit('setSelectedHospital', hospital);
    },
  },

  getters: {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    selectedHospital: state => state.selectedHospital,
  },
});
