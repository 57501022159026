<template>
  <div class="account-management-page">
    <Header />
    <main>
      <h1>Account Management</h1>
      <form @submit.prevent="updateAccount">
        <div class="form-group">
          <label for="name">Name</label>
          <input v-model="name" type="text" id="name" placeholder="Name" required />
        </div>
        <div class="form-group">
          <label for="phone">Mobile</label>
          <vue-tel-input v-model="phone" :defaultCountry="'CH'" mode="international" :enabledCountryCode="true"
            :inputOptions="{ showDialCode: true }" @validate="validatePhone" />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input v-model="email" type="email" id="email" placeholder="Email" required
            :class="{ 'invalid': email && !isEmailValid }" />
          <span v-if="email && !isEmailValid" class="error-message">Bitte gültige Email Adresse eingeben</span>
        </div>
        <div class="form-group">
          <label>Benachrichtigungen</label>
          <div class="checkbox-group">
            <input type="checkbox" id="sms" v-model="notificationPreferences.sms" />
            <label for="sms">SMS</label>
          </div>
          <div class="checkbox-group">
            <input type="checkbox" id="emailNotifications" v-model="notificationPreferences.email" />
            <label for="emailNotifications">Email</label>
          </div>
        </div>
        <div class="form-group">
          <label for="currentPassword">Aktuelles Password</label>
          <input v-model="currentPassword" type="password" id="currentPassword" placeholder="Aktuelles Password" />
        </div>
        <div class="form-group">
          <label for="newPassword">Neues Password (leer lassen wenn kein neues PW vergeben werden soll)</label>
          <input v-model="newPassword" type="password" id="newPassword" placeholder="Neues Password" />
        </div>
        <button type="submit">Speichern</button>
        <p v-if="message" :class="['message', messageType === 'error' ? 'message-error' : 'message-success']">{{ message
          }}</p>
      </form>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import api from '../axios';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const name = ref('');
const phone = ref('');
const email = ref('');
const messageType = ref('');
const currentPassword = ref('');
const newPassword = ref('');
const notificationPreferences = ref({
  sms: false,
  email: false,
});
const message = ref('');
const validatePhone = ({ isValid, phoneNumber }) => {
  if (!phoneNumber) {
    phone.value = '';
    return;
  }
  if (isValid) {
    phone.value = phoneNumber.replace(/\s+/g, '');
  }
};


const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const isEmailValid = computed(() => emailRegex.test(email.value));

const store = useStore();

const fetchAccountData = async () => {
  try {
    const response = await api.get('/account', {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    const user = response.data;
    name.value = user.name;
    phone.value = user.phone;
    email.value = user.email;
    notificationPreferences.value = user.notificationPreferences;
  } catch (error) {
    console.error('Failed to fetch account data:', error.message);
  }
};

const updateAccount = async () => {
  if (!isEmailValid.value) {
    message.value = 'Please enter a valid email address';
    messageType.value = 'error';
    return;
  }

  try {
    const accountData = {
      name: name.value,
      phone: phone.value ? phone.value.replace(/\s+/g, '') : '',
      email: email.value,
      notificationPreferences: notificationPreferences.value,
    };
    
    await store.dispatch('updateAccount', accountData);

    if (currentPassword.value && newPassword.value) {
      try {
        await api.put('/account/change-password', {
          currentPassword: currentPassword.value,
          newPassword: newPassword.value,
        }, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
      } catch (passwordError) {
        message.value = 'Password update failed';
        messageType.value = 'error';
        return;
      }
    }

    message.value = 'Daten erfolgreich aktualisiert';
    messageType.value = 'success';
  } catch (error) {
    message.value = error.response?.status === 500 
      ? 'Server error occurred' 
      : 'Speichern fehlgeschlagen';
    messageType.value = 'error';
  }
};

onMounted(fetchAccountData);
</script>

<style scoped>
.account-management-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.section {
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
}

.section-title {
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
}

form {
  border: none;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input,
select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-group input[type="checkbox"] {
  width: auto;
}

.checkbox-group label {
  margin: 0;
}

button {
  background-color: #007aff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

button:hover {
  background-color: #005bb5;
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.message {
  margin: 1rem auto;
  padding: 0.75rem;
  border-radius: 4px;
  width: calc(100% - 2rem);
  text-align: center;
  box-sizing: border-box;
} 

.message-success {
  background-color: #d4edda;
  color: #155724;
}

.message-error {
  background-color: #f8d7da;
  color: #721c24;
}

.invalid {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  margin: 0 2rem;
  width: 80%;
  max-width: 800px;
  text-align: center;
}

.modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.modal-actions button {
  margin-top: 0;
}

.secondary-button {
  background-color: #6c757d;
}

.secondary-button:hover {
  background-color: #5a6268;
}
</style>
