<template>
  <div class="shift-overview-page">
    <Header />
    <main>
      <h1>Übersicht aller Schichten</h1>
      <div class="filters">
        <label>
          Von:
          <input type="date" v-model="startDate" @change="fetchAllShifts" />
        </label>
        <label>
          Bis:
          <input type="date" v-model="endDate" @change="fetchAllShifts" />
        </label>
        <input type="text" v-model="searchQuery" placeholder="Search..." />
      </div>
      <div class="shift-table-container">
        <table class="shift-table">
          <thead>
            <tr>
              <th @click="sortBy('hospital.name')" class="sortable">
                Krankenhaus
                <i :class="getSortIcon('hospital.name')"></i>
              </th>
              <th @click="sortBy('status')" class="sortable">
                Status
                <i :class="getSortIcon('status')"></i>
              </th>
              <th @click="sortBy('userName')" class="sortable">
                Arbeiter
                <i :class="getSortIcon('userName')"></i>
              </th>
              <th @click="sortBy('types')" class="sortable">
                Funktionen
                <i :class="getSortIcon('types')"></i>
              </th>
              <th @click="sortBy('start')" class="sortable">
                Start Datum & Zeit
                <i :class="getSortIcon('start')"></i>
              </th>
              <th @click="sortBy('end')" class="sortable">
                Ende Datum & Zeit
                <i :class="getSortIcon('end')"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="shift in sortedShifts" :key="shift._id">
              <td>{{ shift.hospital?.name || 'N/A' }}</td>
              <td>
                <span :class="`status-dot ${shift.status.toLowerCase()}`"></span>
                {{ shift.status }}
              </td>
              <td>{{ shift.user ? shift.user.name : 'Nicht zugewiesen' }}</td>
              <td>{{ shift.types.join(', ') }}</td>
              <td>{{ formatDateTime(shift.start) }}</td>
              <td>{{ formatDateTime(shift.end) }}</td>
              <td v-if="shift.status === 'planned'">
                <button @click="openAssignModal(shift)" class="assign-button">
                  Arbeiter zuweisen
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Add assignment modal -->
      <div v-if="showAssignModal" class="modal">
        <div class="modal-content">
          <h3>Arbeiter zuweisen</h3>
          <input type="text" v-model="userSearchQuery" placeholder="Suche nach Name, Email oder Telefon..."
            class="search-input" />
          <div class="users-list">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Telefon</th>
                  <th>Rolle</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in filteredUsers" :key="user._id">
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone || '-' }}</td>
                  <td>{{ user.notificationRole }}</td>
                  <td>
                    <button @click="assignUser(user)" class="assign-user-button">
                      Zuweisen
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-actions">
            <button @click="showAssignModal = false">Abbrechen</button>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import api from '../axios';

const shifts = ref([]);
const searchQuery = ref('');
const startDate = ref(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10));
const endDate = ref(new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().substr(0, 10));
const currentSort = ref('start');
const currentSortDir = ref('asc');
const selectedShift = ref(null);
const showAssignModal = ref(false);
const availableUsers = ref([]);
const userSearchQuery = ref('');

const fetchAllShifts = async () => {
  try {
    const response = await api.get('/shifts/all', {
      params: {
        startDate: startDate.value,
        endDate: endDate.value,
      },
    });
    shifts.value = response.data;
  } catch (error) {
    console.error('Error fetching all shifts:', error);
  }
};

const formatDateTime = (dateTime) => {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Zurich'
  };
  return new Intl.DateTimeFormat('de-DE', options).format(new Date(dateTime));
};

const openAssignModal = async (shift) => {
  selectedShift.value = shift;
  showAssignModal.value = true;
  await fetchAvailableUsers();
};

const fetchAvailableUsers = async () => {
  try {
    const response = await api.get('/admin/users');
    availableUsers.value = response.data.filter(user =>
      user.notificationRole && selectedShift.value.types.includes(user.notificationRole)
    );
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const assignUser = async (user) => {
  try {
    await api.post('/shifts/accept', {
      shiftId: selectedShift.value._id,
      userId: user._id
    });
    showAssignModal.value = false;
    await fetchAllShifts();
  } catch (error) {
    console.error('Error assigning user:', error);
  }
};

// Add computed property for filtered users
const filteredUsers = computed(() => {
  const query = userSearchQuery.value.toLowerCase();
  return availableUsers.value.filter(user =>
    user.name.toLowerCase().includes(query) ||
    user.email.toLowerCase().includes(query) ||
    (user.phone && user.phone.toLowerCase().includes(query))
  );
});

const sortBy = (field) => {
  if (field === currentSort.value) {
    currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc';
  } else {
    currentSort.value = field;
    currentSortDir.value = 'asc';
  }
};

const getSortIcon = (field) => {
  if (field === currentSort.value) {
    return {
      'fas fa-sort-up': currentSortDir.value === 'asc',
      'fas fa-sort-down': currentSortDir.value === 'desc'
    };
  }
  return 'fas fa-sort';
};

const filteredShifts = computed(() => {
  return shifts.value.filter(shift => {
    const search = searchQuery.value.toLowerCase();

    return (
      (shift.hospital?.name?.toLowerCase().includes(search) || 'N/A'.includes(search)) ||
      shift.status.toLowerCase().includes(search) ||
      (shift.user && shift.user.name.toLowerCase().includes(search)) ||
      shift.types.some(type => type.toLowerCase().includes(search)) ||
      (shift.user ? 'Nicht zugewiesen' : '').toLowerCase().includes(search)
    );
  });
});


const sortedShifts = computed(() => {
  return [...filteredShifts.value].sort((a, b) => {
    let aValue = a[currentSort.value];
    let bValue = b[currentSort.value];

    // Handle nested hospital name
    if (currentSort.value === 'hospital.name') {
      aValue = a.hospital.name;
      bValue = b.hospital.name;
    }

    // Handle user name
    if (currentSort.value === 'userName') {
      aValue = a.user ? a.user.name : '';
      bValue = b.user ? b.user.name : '';
    }

    // Handle date fields
    if (['start', 'end'].includes(currentSort.value)) {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    }

    if (aValue < bValue) return currentSortDir.value === 'asc' ? -1 : 1;
    if (aValue > bValue) return currentSortDir.value === 'asc' ? 1 : -1;
    return 0;
  });
});

onMounted(() => {
  fetchAllShifts();
});
</script>

<style scoped>
.shift-overview-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

main {
  padding: 1rem;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: flex-end;
}

.filters label {
  flex: 1 1 100%;
}

.assign-button {
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
}

.assign-button:hover {
  background-color: #005bb5;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.users-list {
  max-height: 400px;
  overflow-y: auto;
  margin: 1rem 0;
}

.users-list table {
  width: 100%;
  border-collapse: collapse;
}

.users-list th,
.users-list td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.assign-user-button {
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.modal-actions button {
  background-color: #6c757d;
}

.filters input[type="date"],
.filters input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  height: 2.5rem;
  font-size: 1rem;
}

.shift-table-container {
  overflow-x: auto;
  width: 100%;
}

.shift-table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
  margin-top: 1rem;
}

th,
td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
  word-wrap: break-word;
  min-width: 150px;
}

th {
  background-color: #f2f2f2;
}

.sortable {
  cursor: pointer;
  user-select: none;
  position: relative;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-dot.planned {
  background-color: orange;
}

.status-dot.confirmed {
  background-color: green;
}

.status-dot.canceled {
  background-color: red;
}

.sortable:hover {
  background-color: #e9e9e9;
}

.sortable i {
  margin-left: 5px;
  font-size: 0.8em;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media (min-width: 600px) {
  .filters {
    flex-wrap: nowrap;
  }

  .filters label {
    flex: 1;
  }
}
</style>
